export default function checkCurrentPage() {
  const pathname = window.location.pathname;
  const href = window.location.href;
  $(`a[href="${pathname}"], a[href="${href}"]`).on("click", function (e) {
    e.preventDefault();
  });
  if (pathname !== '/404') {
    $(`.header__link[href="${pathname}"], .header__link[href="${href}"]`).addClass('active');
    $(`.footer__link[href="${pathname}"], .footer__link[href="${href}"]`).addClass('active');
  }
}
checkCurrentPage();
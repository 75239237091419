export default function toggleFormList() {
  let button = $('.form-block__selected');
  let list = $('.form-block__list');
  let items = $('.form-block__service');
  button.on('click', function() {
    $(this).toggleClass('active');
    $(this).next().toggleClass('active');
  });
  $(document).mouseup(function(e) {
    if (!list.is(e.target) && !button.is(e.target) && button.has(e.target).length === 0) {
      list.removeClass('active');
      button.removeClass('active');
    }
  });
  items.on('click', function() {
    button.addClass('selected');
    button.find('span').text($(this).text());
    button.find('input').val($(this).text());
  });
}
toggleFormList();
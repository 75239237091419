import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export default function toggleMenu() {
  let button = $('.header__burger');
  let menu = $('.header nav');
  let items = $('.header nav a, .header__logo');
  button.on('click', function() {
    if ($(this).hasClass('active')) {
      menu.removeClass('active');
      enablePageScroll();
      $(this).removeClass('active');
    } else {
      menu.addClass('active');
      disablePageScroll();
      $(this).addClass('active');
    }
  });
  Array.from(items).forEach((item) => {
    $(item).on('click', function() {
      setTimeout(function() {
        button.removeClass('active');
        menu.removeClass('active');
        enablePageScroll();
      }, 300);
    });
  });
  $(window).on('resize', function() {
    if ($(window).width() >= 1280 && button.hasClass('active')) {
      button.removeClass('active');
      menu.removeClass('active');
      enablePageScroll();
    }
  });
}
toggleMenu();
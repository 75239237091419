import gsap from 'gsap';

export default function toggleTheme() {
  let button = $('.header__theme');
  let body = $('html, body');
  let currentTheme = localStorage.getItem('theme') || '';
  button.on('click', function() {
    body.toggleClass('dark');
    if (body.hasClass('dark')) {
      $('meta[name="theme-color"]').attr('content', '#111928');
      button.addClass('active');
      localStorage.theme = 'dark';
    } else {
      $('meta[name="theme-color"]').attr('content', '#ffffff');
      button.removeClass('active');
      localStorage.theme = '';
    }
  });
  if (currentTheme == 'dark') {
    $('meta[name="theme-color"]').attr('content', '#111928');
    body.addClass('dark');
    button.addClass('active');
  } else {
    $('meta[name="theme-color"]').attr('content', '#ffffff');
    body.removeClass('dark');
  }
}
toggleTheme();
import checkCurrentPage from "../checks/check-current-page";
import currentYear from "../common/get-current-year";
// import toggleTranslation from "../../components/toggles/toggle-lang";
import toggleTheme from "../../components/toggle/toggle-theme";
import toggleFormList from "../../components/toggle/toggle-form-list";
import sliderCases from "../../components/slider/slider-cases";
import sliderClients from "../../components/slider/slider-clients";
import sliderCompetencies from "../../components/slider/slider-competencies";
import sendForm from "../../components/form/sendForm";

export default function routingFunctions() {
  checkCurrentPage();
  // toggleTranslation();
  currentYear();
  toggleTheme();
  toggleFormList();
  sliderCases();
  sliderClients();
  sliderCompetencies();
  sendForm();
}
import Swiper from "swiper";
import { Pagination, Grid } from 'swiper/modules';

export default function sliderCases() {
  new Swiper('.cases__slider', {
    modules: [Pagination, Grid],
    spaceBetween: 16,
    grid: {
      rows: 1,
      fill: "row"
    },
    breakpoints: {
      1280: {
        grid: {
          rows: 3,
        },
        slidesPerView: 1,
        spaceBetween: 100,
        allowTouchMove: false,
      },
      768: {
        grid: {
          rows: 3,
        },
        slidesPerView: 1,
        spaceBetween: 48,
        allowTouchMove: false,
      }
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true,
      clickable: true,
    },
  });
}
sliderCases();
import Swiper from "swiper";
import { Pagination, Grid } from 'swiper/modules';

export default function sliderCompetencies() {
  new Swiper('.competencies__slider', {
    modules: [Grid, Pagination],
    spaceBetween: 16,
    grid: {
      rows: 1,
      fill: "row"
    },
    breakpoints: {
      1280: {
        grid: {
          rows: 2,
        },
        slidesPerView: 4,
        spaceBetween: 32,
        allowTouchMove: false,
      },
      768: {
        grid: {
          rows: 4,
        },
        slidesPerView: 2,
        spaceBetween: 24,
        allowTouchMove: false,
      }
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true,
      clickable: true,
    },
  });
}
sliderCompetencies();
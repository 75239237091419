import Inputmask from "inputmask";
import sendMail from "./sendMail.js"
import successMessage from "./successMessage.js";
import errorMessage from "./errorMessage.js";

export default function sendForm() {

  const validateName = (name) => {
    if (name.length >= 2 && name.length < 50) {
      return name.match(
        /^[а-яА-Я]/
      );
    }
  }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validatePhone = (phone) => {
    return phone.match(
      /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
    );
  }

  const validatedName = () => {
    const name = $('input[name="name"]').val();
    const surname = $('input[name="surname"]').val();

    if (validateName(name)) {
      $('input[name="name"]').closest('.form-block__input').removeClass('error');
    } else {
      $('input[name="name"]').closest('.form-block__input').addClass('error');
    }
    if (name == 0) {
      $('input[name="name"]').closest('.form-block__input').removeClass('error');
    }
    if (validateName(surname)) {
      $('input[name="surname"]').closest('.form-block__input').removeClass('error');
    } else {
      $('input[name="surname"]').closest('.form-block__input').addClass('error');
    }
    if (surname == 0) {
      $('input[name="surname"]').closest('.form-block__input').removeClass('error');
    }
    return false;
  }

  const validatedEmail = () => {
    const email = $('input[name="email"]').val();

    if (validateEmail(email)) {
      $('input[name="email"]').closest('.form-block__input').removeClass('error');
    } else {
      $('input[name="email"]').closest('.form-block__input').addClass('error');
    }
    if (email == 0) {
      $('input[name="email"]').closest('.form-block__input').removeClass('error');
    }
    return false;
  }

  const validatedPhone = () => {
    const phone = $('input[name="phone"]').val();

    if (validatePhone(phone) && phone.length > 0) {
      $('input[name="phone"]').closest('.form-block__input').removeClass('error');
      if (!$('.form-block__file').hasClass('error')) {
        $('.form-block__button').removeClass('disabled');
      }
    } else {
      $('input[name="phone"]').closest('.form-block__input').addClass('error');
      $('.form-block__button').addClass('disabled');
    }
    return false;
  }

  $('input[name="name"]').on('input', validatedName);
  $('input[name="surname"]').on('input', validatedName);
  $('input[name="email"]').on('input', validatedEmail);
  $('input[name="phone"]').on('input', validatedPhone);

  let file = $('form input[type="file"]');
  let extension;

  file.on('input', (function() {
    extension = file[0].files[0].name.split('.')[1];
    let name = file[0].files[0].name;
    let size = file[0].files[0].size;
    if (size < 10485760 && ('pdf' == extension || 'doc' == extension || 'docx' == extension || 'odt' == extension)) {
      $('.form-block__file span').text(`Файл ${name} добавлен!`);
      $('.form-block__file').removeClass('error').addClass('ok');
      if ($('input[name="phone"]').val().length > 0 && !$('input[name="phone"]').hasClass('error')) {
        $('.form-block__button').removeClass('disabled');
      }
    } else if (size > 10485760) {
      $('.form-block__file span').text('Слишком большой файл!');
      $('.form-block__file').removeClass('ok').addClass('error');
      $('.form-block__button').addClass('disabled');
      errorMessage('Слишком большой файл!');
    } else {
      $('.form-block__file span').text('Неверный формат файла!');
      $('.form-block__file').removeClass('ok').addClass('error');
      $('.form-block__button').addClass('disabled');
    }
  }));

  function sendPopupForm() {
    let form = $('form');
    if (form) {
      form.on('submit', function(e) {
        e.preventDefault();
        if (
          !$('input[name="name"]').closest('.form-block__input').hasClass('error') &&
          !$('input[name="surname"]').closest('.form-block__input').hasClass('error') &&
          !$('input[name="email"]').closest('.form-block__input').hasClass('error') &&
          !$('input[name="phone"]').closest('.form-block__input').hasClass('error')) {
          sendMail(form).then(function() {
            form.get(0).reset();
            if (localStorage.selectedTimeStamp !== undefined || localStorage.services !== undefined) {
              localStorage.removeItem('selectedTimeStamp');
              localStorage.removeItem('services');
            }
            $('.form-block__file span').text('Прикрепить файл');
            $('.form-block__file').removeClass('ok').removeClass('error');
            $('input').removeClass('error');
            $('.form-block__selected').removeClass('selected');
            $('.form-block__selected').find('span').text('Выберите услугу');
            $('.form-block__selected').find('input').val('');
            $('.form-block__button').addClass('disabled');
            if (form.hasClass('brief__form')) {
              return successMessage('Бриф отправлен!');
            } else {
              return successMessage('Ваша заявка отправлена!');
            }
          });
        } else {
          errorMessage('Ошибка отправки!');
        }
      });
    }
  }
  sendPopupForm();

  function inputMask() {
    let input =  $('input[type="tel"]');
    Array.from(input).forEach(function(element) {
      let mask = new Inputmask('+7 (999) 999-99-99');
      mask.mask(element);
    });
  }
  inputMask();
}
setTimeout(function() {
  sendForm();
}, 100);
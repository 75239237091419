function clickCookie() {
  let button = $('.cookie__button');
  let cookie = $('.cookie');
  let classView = localStorage.getItem('cookied');

  !!classView && cookie.fadeIn();
  classView && cookie.fadeOut();

  button.on('click', function() {
    localStorage.setItem('cookied', 'true');
    cookie.fadeOut();
  });
}
clickCookie();